/* tslint:disable */
/* eslint-disable */
/**
 * WaExplorer
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * UserAuthorization(token: str, expires_in: int, name: str, scopes: List[str])
 * @export
 * @interface UserAuthorization
 */
export interface UserAuthorization {
    /**
     * 
     * @type {string}
     * @memberof UserAuthorization
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof UserAuthorization
     */
    expires_in: number;
    /**
     * 
     * @type {string}
     * @memberof UserAuthorization
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAuthorization
     */
    scopes: Array<string>;
}

/**
 * Check if a given object implements the UserAuthorization interface.
 */
export function instanceOfUserAuthorization(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "expires_in" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "scopes" in value;

    return isInstance;
}

export function UserAuthorizationFromJSON(json: any): UserAuthorization {
    return UserAuthorizationFromJSONTyped(json, false);
}

export function UserAuthorizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthorization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'expires_in': json['expires_in'],
        'name': json['name'],
        'scopes': json['scopes'],
    };
}

export function UserAuthorizationToJSON(value?: UserAuthorization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'expires_in': value.expires_in,
        'name': value.name,
        'scopes': value.scopes,
    };
}

