import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {SubscriptionQuery} from "../Model/SubscriptionQuery";
import {UnifiedSubscription, unifiedSubscriptionKey} from "../Model/UnifiedSubscription";
import {SubscriptionsApi} from "../Api/SubscriptionsApi";
import {apiProvider} from "./apiProvider";

export interface UserSubscriptionsState {
    query: SubscriptionQuery | null,
    subscriptions: UnifiedSubscription[] | null
    selected: UnifiedSubscription | null,
    loadMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
    refreshed: UnifiedSubscription | null
}
export const initialState: UserSubscriptionsState = {
    query: null,
    subscriptions: null,
    selected: null,
    loadMessage: null,
    status: 'idle',
    refreshed: null
}

export const loadSubscriptionsAsync = createAsyncThunk(
    'userSubscriptions/loadSubscriptionsAsync',
    apiProvider(SubscriptionsApi, (request: SubscriptionQuery, api: SubscriptionsApi, state) => {
        const refreshed = state.userSubscriptions.refreshed
        return api.queryUserSubscriptions(request).then(subscriptions => {
            return {subscriptions, refreshed}
        })
    })
)

export const userSubscriptionsSlice = createSlice({
    name: 'userSubscriptions',
    initialState,
    reducers: {
        selectSubscription: (state, action: PayloadAction<UnifiedSubscription | null>) => {
            state.selected = action.payload
            state.refreshed = null
        },
        changeUserSubscriptionQuery: (state, action: PayloadAction<SubscriptionQuery | null>) => {
            state.query = action.payload
            state.subscriptions = null
            state.refreshed = null
        },
        refreshSubscriptions: (state) => {
            if (state.query !== null) {
                state.query = {...state.query}
                state.subscriptions = null;
                state.refreshed = state.selected
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadSubscriptionsAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadSubscriptionsAsync.fulfilled, (state, action) => {
            const {subscriptions, refreshed} = action.payload
            state.status = 'idle'
            state.loadMessage = null
            state.subscriptions = subscriptions
            state.refreshed = null
            // state.selected = refreshed !== null ? subscriptions.find(s => unifiedSubscriptionKey(s) === unifiedSubscriptionKey(refreshed)) ?? null : null
        }).addCase(loadSubscriptionsAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.subscriptions = null
            state.loadMessage = action.error.message ?? 'Error occurred loading subscriptions'
            state.selected = null
            state.refreshed = null
        })
    }
})

export default userSubscriptionsSlice.reducer;
export const { selectSubscription, changeUserSubscriptionQuery, refreshSubscriptions } = userSubscriptionsSlice.actions;
export const selectUserSubscriptionsState = (state: RootState) => state.userSubscriptions;
