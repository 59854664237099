export const storage = localStorage

export enum StorageKey {
    ACCESS_TOKEN = 'access_token',
    USER = 'user',

    HISTORY_USERS = 'history.query.users',

    HISTORY_PHONES = 'history.query.phones'
}

export const setStorage = (key: StorageKey, value: string | null) => {
    if (value !== null) {
        storage.setItem(key, value)
    } else {
        storage.removeItem(key)
    }
}

export const getStorage = (key: StorageKey): string | null => {
    return storage.getItem(key)
}