import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Placement} from "react-bootstrap/types";
import {ReactElement, ReactNode} from "react";

export interface SimpleTooltipProps {
    placement?: Placement
    children: ReactElement
    tooltip: (() => (ReactNode | string | null)) | ReactNode | string | null
}

export const SimpleTooltip = (props: SimpleTooltipProps) => {
    const toolTipFn: () => (ReactNode | string | null) = typeof props.tooltip === 'function' ? props.tooltip : () => (props.tooltip as ReactNode | string | null)
    return (
        <OverlayTrigger
            placement={props.placement}
            overlay={
                <Tooltip>
                    <span>{toolTipFn()}</span>
                </Tooltip>
            }>
            {props.children}
        </OverlayTrigger>
    )
}