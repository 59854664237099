import {ReactElement, useEffect, useState} from "react";
import {SimpleTooltip} from "./SimpleTooltip";
import {Clipboard} from "react-bootstrap-icons";
import styles from './Copyable.module.css'
import {copyTextToClipboard} from "../Core/ClipboardUtils";

export const Copyable = ({ value, children }: { children: ReactElement, value: string | null | undefined }) => {
    const [success, setSuccess] = useState<boolean | null>(null)
    useEffect(() => {
        let timeout: NodeJS.Timeout | null = null
        if (success !== null) {
            timeout = setTimeout(() => setSuccess(null),1000)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [success])
    if (value === null || value === undefined) {
        return children
    }
    const onClick = () => {
        copyTextToClipboard(value, (success) => {
            setSuccess(success)
        })
    }
    return (
        <SimpleTooltip placement='left' tooltip={success === null ? <span><Clipboard /></span> : success ? 'Copied!' : 'Not copied :('}>
            <div className={styles.pointer} onClick={onClick}>
                {children}
            </div>
        </SimpleTooltip>
    )
}