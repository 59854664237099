import {WaUserSubscriptionDto} from "../Schema/models/WaUserSubscriptionDto";
import {SubscriptionPhoneDto} from "../Schema/models/SubscriptionPhoneDto";

export type UnifiedSubscription = {
    type: 'WaUserSubscriptionDto',
    model: string,
    subscription: WaUserSubscriptionDto
} | {
    type: 'SubscriptionPhoneDto',
    model: string,
    subscription: SubscriptionPhoneDto
}

export const unifiedSubscriptionKey = (unifiedSubscription: UnifiedSubscription): any => {
    switch (unifiedSubscription.type) {
        case "SubscriptionPhoneDto": return unifiedSubscription.subscription.id
        case "WaUserSubscriptionDto": return unifiedSubscription.subscription.subscription_id
        default: return "never"
    }
}

export const unifiedSubscriptionPhone = (unifiedSubscription: UnifiedSubscription): string => {
    switch (unifiedSubscription.type) {
        case "SubscriptionPhoneDto": return unifiedSubscription.subscription.phone_num.toString()
        case "WaUserSubscriptionDto": return unifiedSubscription.subscription.phone_num
    }
}

export const unifiedSubscriptionActive = (unifiedSubscription: UnifiedSubscription): boolean => {
    switch (unifiedSubscription.type) {
        case "SubscriptionPhoneDto": return unifiedSubscription.subscription.is_active
        case "WaUserSubscriptionDto": return unifiedSubscription.subscription.is_active
        default: return false;
    }
}
