import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ModelMetadata} from "../Schema/models/ModelMetadata";
import {ModelsApi} from "../Api/ModelsApi";
import {RootState} from "./store";
import {apiProvider} from "./apiProvider";

export interface ModelsState {
    models: ModelMetadata[] | null,
    selected: ModelMetadata | null,
    loadMessage: string | null,
    validationMessage: string | null,
    status: 'idle' | 'loading' | 'refresh_required'
}
export const initialState: ModelsState = {
    models: null,
    selected: null,
    loadMessage: null,
    validationMessage: null,
    status: 'idle'
}

export const loadModelsAsync = createAsyncThunk<ModelMetadata[], void, { state: RootState }>(
    'models/loadModelsAsync',
    apiProvider(ModelsApi, (request: void, api: ModelsApi) => {
        console.log('loadModelsAsync')
        return api.queryModels()
    })
)

export const modelsSlice = createSlice({
    name: 'models',
    initialState,
    reducers: {
        selectModel: (state, action: PayloadAction<ModelMetadata | null>) => {
            state.selected = action.payload
        },
        modelsValidation: (state, action: PayloadAction<string | null>) => {
            state.validationMessage = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadModelsAsync.pending, (state) => {
            state.status = 'loading'
            state.loadMessage = null
        }).addCase(loadModelsAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            state.loadMessage = null
            state.models = action.payload
            state.selected = state.selected !== null ?
                action.payload.find(e => e.name === state.selected?.name) ?? null
            : null
        }).addCase(loadModelsAsync.rejected, (state, action) => {
            state.status = action.payload === 'refresh_required' ? 'refresh_required' : 'idle'
            state.models = null
            state.loadMessage = action.error.message ?? 'Error occurred loading models'
            state.selected = null
        })
    }
})

export default modelsSlice.reducer;
export const { selectModel, modelsValidation } = modelsSlice.actions;
export const selectModelsState = (state: RootState) => state.models;
