import {Api} from "./Api";
import {UsersQuery} from "../Model/UsersQuery";
import {UnifiedUser} from "../Model/UnifiedUser";
import {WaUserDto} from "../Schema/models/WaUserDto";
import {AppUserDto} from "../Schema/models/AppUserDto";
import {Typed} from "../Schema/models/Typed";

export class UsersApi extends Api {

    static queryToParams(query: UsersQuery): object {
        return { token: query.token, user_id: query.user_id, model: query.model }
    }

    static unifyTypedUsers(typed: Typed[]): UnifiedUser[] {
        return typed.map(typedUser => {
            switch (typedUser.type) {
                case 'AppUserDto': {
                    return { type: typedUser.type, model: typedUser.model, user: typedUser.data as AppUserDto }
                }
                case 'WaUserDto': {
                    return { type: typedUser.type, model: typedUser.model, user: typedUser.data as WaUserDto }
                }
                default: {
                    throw new Error(`Could not transform user ${typedUser.type}@${typedUser.model} to unified user`)
                }
            }
        })
    }

    async queryUsers(query: UsersQuery): Promise<UnifiedUser[]> {
        return this.newCall<Typed[]>('users/query')
            .withQueries(UsersApi.queryToParams(query))
            .fetchJson()
            .then(e => UsersApi.unifyTypedUsers(e))
    }
}