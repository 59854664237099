import {UnifiedContract} from "../../Core/Model/UnifiedContract";
import styles from "./UnifiedContractRowView.module.css";
import {Badge, CloseButton} from "react-bootstrap";
import * as React from "react";
import {Variant} from "react-bootstrap/types";
import {Clock} from "react-bootstrap-icons";

const UnifiedContractContent = ({ unifiedContract }: { unifiedContract: UnifiedContract }) => {
    switch (unifiedContract.type) {
        case "PaymentDto": {
            const status = unifiedContract.contract.is_active
            const statusVariant: Variant = status ? 'success' : 'danger'
            const method = unifiedContract.contract.pay_type
            const methodVariant = method === 'Apple' ? 'info' :
                method === 'Google' ? 'success' :
                    method === 'stripe' ? 'primary' : 'dark'
            return (
                <>
                    <div className={styles.title}>
                        <div className={styles.id}>{unifiedContract.contract.id}</div>
                        <Badge bg={methodVariant}>{method}</Badge>
                        <Badge bg={statusVariant}>{status}</Badge>
                    </div>
                    <div className={styles.plan}>
                        {unifiedContract.contract.subs_amount} contacts
                    </div>
                    <div className={styles.interval}>
                        <Clock size='1.2em' />
                        <div className={styles.date}>
                            <div>{unifiedContract.contract.fulfil_time.toString()}</div>
                            <div>{unifiedContract.contract.valid_until.toString()}</div>
                            <div className={styles.date_info}>{unifiedContract.contract.time_info}</div>
                        </div>
                    </div>
                </>
            )
        }
        case "WaUserContractDto": {
            const status = unifiedContract.contract.contract_status.contract_status_name
            const statusVariant: Variant = status === 'Actual' ? 'success' : 'danger'
            const method = unifiedContract.contract.payment_method?.payment_method_name
            const methodVariant = method === 'Apple' ? 'info' :
                method === 'Google' ? 'success' :
                method === 'stripe' ? 'primary' : 'dark'
            return (
                <>
                    <div className={styles.title}>
                        <div className={styles.id}>{unifiedContract.contract.contract_id}</div>
                        <Badge bg={methodVariant}>{unifiedContract.contract.payment_method?.payment_method_name ?? "unknown method"}</Badge>
                        <Badge bg={statusVariant}>{status}</Badge>
                    </div>
                    <div className={styles.plan}>
                        <span>{unifiedContract.contract.contract_type.contract_type_name}</span>
                        <Badge>{unifiedContract.contract.contract_type.subscriptions_amount} contacts</Badge>
                    </div>
                    <div className={styles.interval}>
                        <Clock size='1.2em' />
                        <div className={styles.date}>
                            <div>{unifiedContract.contract.begin_date.toString()}</div>
                            <div>{unifiedContract.contract.end_date.toString()}</div>
                            <div className={styles.date_info}>{unifiedContract.contract.time_info}</div>
                            <div></div>
                        </div>
                    </div>
                </>
            )
        }
        default: {
            return (
                <>Unknown contract type</>
            )
        }
    }
}

export const UnifiedContractRowView = ({ unifiedContract }: { unifiedContract: UnifiedContract }) => {
    return (
        <div>
            <UnifiedContractContent unifiedContract={unifiedContract} />
        </div>
    )
}