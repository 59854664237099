import {WaUserDto} from "../Schema/models/WaUserDto";
import {AppUserDto} from "../Schema/models/AppUserDto";

export type UnifiedUser = {
    type: 'WaUserDto',
    model: string,
    user: WaUserDto
} | {
    type: 'AppUserDto',
    model: string,
    user: AppUserDto
}

export const unifiedUserKey = (unifiedUser: UnifiedUser): any => {
    switch (unifiedUser.type) {
        case "AppUserDto": return unifiedUser.user.id
        case "WaUserDto": return unifiedUser.user.user_id
        default: return "never"
    }
}